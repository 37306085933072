<template>
  <div class="background">
    <BaseHeaderB></BaseHeaderB>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="7" md="4">
          <v-tabs left style="margin-top: 15%">
            <v-tab style="margin-left: 15%">Sign Up</v-tab>
            <v-tab>Log in</v-tab>
            <v-tab-item>
              <BaseRegistration class="mx-auto"></BaseRegistration>
            </v-tab-item>
            <v-tab-item>
              <BaseLogin class="mx-auto"></BaseLogin>
            </v-tab-item>
          </v-tabs>
        </v-col>
        <v-col class="image" cols="12" sm="5" md="8"></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      form: {},
    };
  },
  methods: {},
  computed: {
    ...mapState(["auth"]),
  },
};
</script>

<style>
.image {
  background-image: url("../../public/Images.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin-top: -90px;
}

@media screen and (max-width: 600px) {
  .image {
    display: none;
  }
}
</style>
